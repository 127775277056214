<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>

            <el-breadcrumb-item>BANNER管理</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="toCreate">添加</el-button>
        </el-col>
      </el-row>
    </div>

    <el-container>
      <!--分类-->
      <el-aside width="200px">
        <el-tree :data="treedata" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </el-aside>
      <el-main>
        <!-- 列表内容 -->
        <div class="main">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-input v-model="name" placeholder="请输入广告名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getList">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="adminList" border="" style="width: 100%">
            <el-table-column prop="sorder" label="序号" width="95" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.sorder" @change="updatesort(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="iType" label="显示位置" align="center">
              <template slot-scope="scope">{{ treedata.find(q => q.value == scope.row.iType).label }}</template>
            </el-table-column>
            <el-table-column prop="advName" label="广告名称" align="center"></el-table-column>
            <el-table-column label="广告略图" width="110" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.advImg" alt="广告名称" class="table-image">
              </template>
            </el-table-column>
            <el-table-column prop="advUrl" label="小程序页面路径" align="center"></el-table-column>

            <el-table-column prop="addDate" label="添加时间" align="center"></el-table-column>
            <el-table-column prop="state" label="状态" width="60">
              <template slot-scope="scope"> {{ ['禁用', '启用'][scope.row.state] }} </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="213" align="center">
              <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="setStates(scope.row.id, 0, scope.row)"
                  v-if="scope.row.state == 1">禁用</el-button>
                <el-button type="success" size="mini" @click="setStates(scope.row.id, 1, scope.row)" v-else>启用
                </el-button>
                <el-button size="mini" @click="toEdit(scope.row.id)">编辑</el-button>
                <el-button type="danger" size="mini" @click="onDelete(scope.row.id)">刪除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex"
            :total="pageTotal" @current-change="onCurrentChange" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getListRequest, setSortRequest, setStatesRequest, delRequest } from '@/api/adv'
export default {
  name: 'advlist',
  data() {
    return {
      name: '',
      iType: '',
      adminList: [],
      userName: '',
      pageIndex: 1,
      pageTotal: 0,
      treedata: [
        {
          label: '轮播图（大）',
          value: 0,
        },

        {
          label: '首页banner图1.1',
          value: 5,
        }, {
          label: '品牌起源1.1',
          value: 6,
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getListRequest({
        AdvName: this.name,
        iType: this.iType,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then((res) => {
        let data = res.data
        this.adminList = data.data
        this.pageTotal = data.count
      })
    },
    handleNodeClick(data) {
      console.log(data.value)
      this.iType = data.value
      this.getList()
    },
    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder,
      }).then((res) => {
        row.sorder = row.sorder
      })
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        State: states,
      }).then((res) => {
        row.state = states
      })
    },
    toCreate() {
      this.$router.push({
        path: '/adv/edit',
        query: { id: 0 },
      })
    },

    toEdit(id) {
      this.$router.push({
        path: '/adv/edit',
        query: { id: id },
      })
    },
    onDelete(id) {
      this.$confirm('确认删除该行吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 调用删除接口
          return delRequest({ id })
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: `删除成功！`,
          })
          this.getList()
        })
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
  },
}
</script>
 


 
<style lang="less" scoped>

</style>
